<section class="page-section">
    <div class="container">
        <div class="section-title white">
              <h2 class="title">SOCIAL MEDIA SERVICES

            </h2>
        </div>
        <div class="row">
            <!-- <div class="col-md-4 col-sm-6 text-center" data-animation="fadeInLeft">
                  <img src="img/sections/mobile-img1.png" width="590" height="415" alt="" />
            </div> -->
            <div class="col-md-12 col-sm-12" data-animation="fadeInRight">
                 <p>Social media marketing involves connecting, interacting and succeeding together of business with their customers. The social media platforms like Facebook, Youtube, Instagram etc plays crucial role in boosting the business of any organisation. </p>         
                 <p>As a best Social Media Marketing service company, we provide the best social media marketing services for businesses to enhance their popularity. We help you advertise at the right place and at the right time! Our in-house expertise includes the best Social Media Marketing strategies to improve your company’s social presence. </p>
                 <p>Depending on your requirement, we design customised social media marketing specifically developed for your brand and your market. Your social media performance are analysed and reviewed to determine the next social media strategy. Based on the analytics we run new experiments to optimise social media content.

                </p>

            </div>
        </div>
    </div>
</section>
<br>
<br>
<br>
<br>