<div
  id="carousel-example-generic1"
  class="carousel slide mobile"
  data-ride="carousel"
>
  <ol class="carousel-indicators">
    <li
      data-target="#carousel-example-generic1"
      data-slide-to="0"
      class="active"
    ></li>
    <li data-target="#carousel-example-generic1" data-slide-to="1"></li>
    <!-- <li data-target="#carousel-example-generic1" data-slide-to="2"></li> -->
  </ol>
  <div class="carousel-inner" role="listbox">
    <div class="item active">
      <img
        src="assets/img/sections/slider/webimagesslider/ds1.png"
        width="1900"
        height="600"
        alt=""
        title=""
      />
    </div>
    <div class="item">
      <img
        src="assets/img/sections/slider/webimagesslider/ds2.png"
        width="1900"
        height="570"
        alt=""
        title=""
      />
    </div>
    <!-- <div class="item">
      <img
        src="assets/img/sections/slider/webimagesslider/ds3.png"
        width="1900"
        height="570"
        alt=""
        title=""
      />
    </div> -->
  </div>
  <a
    class="left carousel-control"
    href="#carousel-example-generic1"
    role="button"
    data-slide="prev"
  >
    <span
      class="fa fa-angle-left fa-2x"
      aria-hidden="true"
      style="display: none"
    ></span>
    <span class="sr-only">Previous</span></a
  >
  <a
    class="right carousel-control"
    href="#carousel-example-generic1"
    role="button"
    data-slide="next"
  >
    <span
      class="fa fa-angle-right fa-2x"
      aria-hidden="true"
      style="display: none"
    ></span>
    <span class="sr-only">Next</span></a
  >
</div>

<div
  id="carousel-example-generic1"
  class="carousel slide device"
  data-ride="carousel"
>
  <ol class="carousel-indicators">
    <li
      data-target="#carousel-example-generic1"
      data-slide-to="0"
      class="active"
    ></li>
    <li data-target="#carousel-example-generic1" data-slide-to="1"></li>
    <!-- <li data-target="#carousel-example-generic1" data-slide-to="2"></li> -->
  </ol>
  <div class="carousel-inner" role="listbox">
    <div class="item active">
      <img
        src="assets/img/sections/slider/webimagesslider/ms1.png"
        width="1900"
        height="800"
        alt=""
        title=""
      />
    </div>
    <div class="item">
      <img
        src="assets/img/sections/slider/webimagesslider/ms2.png"
        width="1900"
        height="570"
        alt=""
        title=""
      />
    </div>
    <!-- <div class="item">
      <img
        src="assets/img/sections/slider/webimagesslider/ms3.png"
        width="1900"
        height="570"
        alt=""
        title=""
      />
    </div> -->
  </div>
  <a
    class="left carousel-control"
    href="#carousel-example-generic1"
    role="button"
    data-slide="prev"
  >
    <span
      class="fa fa-angle-left fa-2x"
      aria-hidden="true"
      style="display: none"
    ></span>
    <span class="sr-only">Previous</span></a
  >
  <a
    class="right carousel-control"
    href="#carousel-example-generic1"
    role="button"
    data-slide="next"
  >
    <span
      class="fa fa-angle-right fa-2x"
      aria-hidden="true"
      style="display: none"
    ></span>
    <span class="sr-only">Next</span></a
  >
</div>

<section id="testimonials" class="page-section" style="padding: 5px 0">
  <div class="container">
    <div class="row">
      <div class="col-md-offset-1 col-md-5 testimonails">
        <div class="section-title mob-s" style="margin-bottom: 0px">
          <!-- Heading -->
          <h2 class="title" style="font-size: 14px; padding: 0px 0">Clients</h2>
        </div>

        <div
          class="owl-carousel dark-switch"
          data-pagination="false"
          data-autoplay="true"
          data-navigation="false"
          data-items="1"
          data-desktop="1"
          style="margin-top: -23px"
        >
          <!-- <div class="item pad-10" data-animation="fadeInLeft">
            <div class="client-details text-center">
              <img
                src="assets/img/wesbitelogopngformat/fynzon.png"
                alt=""
                title=""
                width="30"
                height="40"
                style="margin-bottom: -7px"
                class="macbook"
              />
              <div class="client-details">
                <strong class="text-color col">Fynzon</strong>
              </div>
            </div>
          </div> -->

          <div class="item pad-10" data-animation="fadeInLeft">
            <div class="client-details text-center">
              <img
                src="assets/img/wesbitelogopngformat/renzGlobal.png"
                alt=""
                title=""
                width="50"
                height="70"
                style="margin-bottom: -7px"
                class="macbook"
              />
              <div class="client-details">
                <strong class="text-color col">Renz Global</strong>
              </div>
            </div>
          </div>
          <div class="item pad-10" data-animation="fadeInLeft">
            <div class="client-details text-center">
              <img
                src="assets/img/wesbitelogopngformat/care4wheel.jpg"
                alt=""
                title=""
                width="60"
                height="70"
                style="margin-bottom: -7px"
                class="macbook"
              />
              <div class="client-details">
                <strong class="text-color col">care4wheel</strong>
              </div>
            </div>
          </div>

          <div class="item pad-10" data-animation="fadeInLeft">
            <div class="client-details text-center">
              <img
                src="assets/img/wesbitelogopngformat/exchangetc.png"
                alt=""
                title=""
                width="50"
                height="50"
                style="margin-bottom: -7px"
                class="macbook"
              />
              <div class="client-details">
                <strong class="text-color col"
                  >Virtual Block Innovations pvt ltd
                </strong>
              </div>
            </div>
          </div>

          <!-- <div class="item pad-10">
                                <div class="client-details text-center">
                                    <img src="assets/img/wesbitelogopngformat/safalway.png" alt="" title="" width="83" height="83" style="margin-bottom: -7px;" />
                                    <div class="client-details">
                                         <strong class="text-color col">Safal way marketing pvt ltd</strong>
                                    </div>
                                </div>
                            </div> -->

          <div class="item pad-10">
            <div class="client-details text-center">
              <img
                src="assets/img/wesbitelogopngformat/tendaSafari.png"
                alt=""
                title=""
                width="55"
                height="55"
                style="margin-bottom: -7px"
              />
              <div class="client-details">
                <strong class="text-color col">Tendo safaris</strong>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 text-center">
        <div class="section-title" style="margin-bottom: 35px !important">
          <!-- Heading -->
          <h2 class="title iphone" style="font-size: 14px; padding: 0px 0">
            Testimonials
          </h2>
        </div>

        <div
          class="owl-carousel pagination-1 dark-switch"
          data-pagination="false"
          data-autoplay="true"
          data-navigation="false"
          data-items="1"
          data-desktop="1"
          style="margin-top: -25px"
        >
          <!-- <div class="item pad-10" data-animation="fadeInLeft">
            <div class="">
              <blockquote class="small-text text-center fsize">
                The exchange platform where blockchain-based cryptocurrencies
                are being traded. Fynzon is a "Platform having 360° financial
                zone".
              </blockquote>
            </div>
            <div class="client-details text-center">
              <div class="client-details ss">
                <strong class="text-color col ss">John Doe</strong>
              </div>
            </div>
          </div> -->
          <div class="item pad-10">
            <div class="">
              <p class="small-text text-center fsize">
                Renz Global helps you to become an entrepreneur, self-dependent,
                self-earner for a best life.So with RenzGlobal, Be Wise, Be
                Wealthy.
              </p>
            </div>
            <!-- <div class="client-details text-center">
                                   
                                    <div class="client-details ss">
                                        
                                        <strong class="text-color col ">John Doe</strong>
                                    </div>
                                </div> -->
          </div>
          <div class="item pad-10">
            <div class="">
              <p class="small-text text-center fsize">
                care4wheel enthusiastically provides car washing & detailing
                solutions at your doorstep for people who know how to drive &
                when to drive .
              </p>
            </div>
            <!-- <div class="client-details text-center">
                                 
                                    <div class="client-details ss">
                                       
                                        <strong class="text-color col ">John Doe</strong>
                                       </div>
                                </div> -->
          </div>

          <div class="item pad-10">
            <div class="">
              <p class="small-text text-center fsize">
                VBI helps others to create and improve digital products through
                modern web application development. We also consult
                entrepreneurs to create sustainable partnerships.
              </p>
            </div>
            <!-- <div class="client-details text-center">
                                 
                                    <div class="client-details ss">
                                       
                                        <strong class="text-color col ">John Doe</strong>
                                       </div>
                                </div> -->
          </div>
          <div class="item pad-10">
            <div class="">
              <p class="small-text text-center fsize">
                A Travel Company provides Travel Solutions & Management - Hotels
                Bookings & Accommodations around the World - Transportation -
                Holiday Packages.
              </p>
            </div>
            <!-- <div class="client-details text-center">
                                 
                                    <div class="client-details ss">
                                       
                                        <strong class="text-color col ">John Doe</strong>
                                       </div>
                                </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- 
        <div class="container">
            <h3>Popover Example</h3>
              <a href="#" title="Dismissible popover" data-toggle="popover" data-trigger="focus" data-content="Click anywhere in the document to close this popover">Click me</a>
          </div>
           -->
