<div class="container-fluid" style="margin-top: 100px">
  <div class="row">
    <div class="col-md-8">
      <!-- <img alt="web" src="../../assets/img/m10.png" width="80px" /> -->
      <h2>Mobile App Development Service</h2>
      <p class="text-justify">
        Mobile app technologies are empowering people and businesses to conduct
        processes interactively and instantaneously. Much like our other digital
        initiatives, mobile application development services are also led with
        the primary objective of elevating the customer experience and enabling
        their market success.
      </p>
      <p class="text-justify">
        Mobile App development service utilizes technological expertise and
        knowledge of diverse business domains to deliver projects that achieve
        dynamic business objectives. Our business-driven approach brings value
        to your business faster than ever. We strive to deliver mobile
        applications that display technological excellence and high quality.
      </p>
    </div>
    <div class="col-sm-4">
      <img alt="web" src="../../assets/img/mobile-app.jpg" />
    </div>
  </div>

  <div class="container" style="margin-top: 100px; margin-bottom: 50px">
    <div class="text-center margin-bottom1">
      <h4>Choose The Best Plan That’s For You</h4>
    </div>
    <div class="row">
      <div class="col-md-6 margin-top1">
        <div class="card text-center">
          <div class="title">
            <i class="fa fa-rocket" aria-hidden="true"></i>
            <h2>Android Plan</h2>
          </div>
          <div class="price">
            <h4>
              ₹ 2,00,000
              <!-- <span style="font-size: 20px">/month</span> -->
            </h4>
          </div>
          <div class="option">
            <ul>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Code optimization
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Upgrading the app
                to make it compatible with new technologies
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Maintenance
                Included
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Unlimited Images &
                Videos
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Cost Effective &
                Professional
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>24/7 Support
                (Phone/Email/Chat)
              </li>
            </ul>
          </div>
          <a (click)="AddtoCart(MobileAppData?.plan_details[0])">Add to cart</a>
        </div>
      </div>
      <div class="col-md-6 margin-top1">
        <div class="card text-center">
          <div class="title">
            <i class="fa fa-rocket" aria-hidden="true"></i>
            <h2>iOS Plan</h2>
          </div>
          <div class="price">
            <h4>
              ₹ 7,00,000
              <!-- <span style="font-size: 20px">/month</span> -->
            </h4>
          </div>
          <div class="option">
            <ul>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Code optimization
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Upgrading the app
                to make it compatible with new technologies
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Maintenance
                Included
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Unlimited Images &
                Videos
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Cost Effective &
                Professional
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>24/7 Support
                (Phone/Email/Chat)
              </li>
            </ul>
          </div>
          <a (click)="AddtoCart(MobileAppData?.plan_details[1])">Add to cart</a>
        </div>
      </div>
    </div>
  </div>
</div>
