<footer id="footer" class="">
  <div class="copyright device">
    <div class="container">
      <div class="row">
        <div
          class="col-xs-12 col-sm-6 col-md-12 widget bottom-xs-pad-20"
          style="text-align: center; font-size: 10px"
        >
          <a href="terms" style="color: white">
            <img alt="" style="width: 15px" src="assets/img/tnc.png" /> &nbsp;
            Terms & Conditions</a
          >
          &nbsp;
          <!-- <a href="#" style="color:white"> <img style="width: 15px;"  alt="" src="assets/img/blog.png">&nbsp; Blogs</a>&nbsp; &nbsp;  -->
          <!-- <a href="team" style="color:white"> <img  style="width: 15px;" alt="" src="assets/img/team.png">&nbsp; Join our Team</a>-->
          <br />

          Copyright &copy; 2020 <a href="http://zozothemes.com"></a>Virtual Gain
          Technologies - All Rights Reserevd.
        </div>
      </div>
    </div>
  </div>

  <!-- footer-top -->
  <div class="copyright mobile">
    <div class="container-fluid">
      <div class="row" style="margin-bottom: 3px">
        <!-- <div class="col-xs-10 col-sm-6 col-md-3">
                 <a href="terms" style="color:white;">     <img  alt="" src="assets/img/cofiar-partner.jpeg" width="40" height="40">
                    </a> 
                     
                &nbsp;&nbsp;
                   
                  <span style="font-size: 14px;">Our Marketing Partner</span>
              </div> -->

        <div class="col-xs-10 col-sm-6 col-md-6">
          <a href="terms" style="color: white"
            ><img alt="" src="assets/img/tnc.png" /> &nbsp; TERMS & CONDITIONS
          </a>
          <!-- <a href="#" style="color:white"> <img  alt="" src="assets/img/blog.png">&nbsp; BLOGS</a>&nbsp; &nbsp;  -->
          <!-- <a href="team" style="color:white"> <img  alt="" src="assets/img/team.png">&nbsp; JOUR OUR TEAM</a>  -->

          <br />

          Copyright &copy; 2020 <a href="http://zozothemes.com"></a>Virtual Gain
          Technologies - All Rights Reserevd.
        </div>

        <div class="col-xs-10 col-sm-6 col-md-2">
          <!-- <a href="terms" style="color:white;">     <img  alt="" src="assets/img/cofiar-partner.jpeg" width="40" height="40">
                    </a> 
                     
                &nbsp;&nbsp;
                   
                  <span style="font-size: 14px;">Our Marketing Partner</span> -->
        </div>

        <div class="col-xs-12 col-sm-6 col-md-4 widget bottom-xs-pad-20">
          <div class="">
            <div class="row">
              <div class="col-md-8" style="margin-top: 3px">
                <a
                  href="/#"
                  title="Dismissible popover"
                  data-placement="top"
                  data-toggle="popover"
                  data-html="true"
                  data-container="body"
                  data-trigger="focus"
                  data-content='
                              <div class="row white">
                                  <div class="item-box icons-color hover-white col-sm-6 col-md-4">
                                    
                                      <i class="icon-shrink fa-2x"></i> 
                                      <a href="/custom-web-developement"><h6  class="title" style="margin: 0 0 13px;">CUSTOMIZED WEB DEVELOPMENT</h6></a>
  
                                      <i class="icon-shrink fa-2x"></i> 
                                    
                                      <a href="/mobileapp"> <h6 class="title" style="margin: 0 0 13px;">MOBILE APP DEVELOPMENT
                                   </h6></a>
  
                                   <i class="icon-shrink fa-2x"></i> 
                                     
                                    <a href="/InternetMarketing">  <h6 class="title" style="margin: 0 0 13px;">DIGITAL MARKETING
                                      </h6></a>
                                  
                                 </div>
                                  <div class="item-box icons-color hover-white col-sm-6 col-md-4">
                                    <i class="icon-shrink fa-2x"></i> 
                                    <a href="/video-creation"><h6 class="title" style="margin: 0 0 13px;">VIDEO CREATION</h6></a>
  
                                    <i class="icon-shrink fa-2x"></i> 
                                    <a href="/label-design"><h6  class="title" style="margin: 0 0 13px;">PACKAGE AND LABEL DESIGN</h6></a>
  
                                    <i class="icon-shrink fa-2x"></i> 
                                    <a href="/logo-design"><h6  class="title" style="margin: 0 0 13px;">LOGO DESIGN</h6></a>  
                                  </div>
                                  <div class="item-box icons-color hover-white col-sm-6 col-md-4">
                                    <i class="icon-shrink fa-2x"></i> 
                                     
                                    <a href= "/server-cloud-service"> <h6 class="title" style="margin: 0 0 13px;">SERVER & CLOUD
                                     </h6> </a>
  
                                      <i class="icon-shrink fa-2x"></i> 
                                     
                                      <a href= "/email-offiec365-service"> <h6 class="title" style="margin: 0 0 13px;">EMAIL & OFFICE365
                                       </h6> </a>
                                       <i class="icon-shrink fa-2x"></i> 
                                     
                                     <a href= "/aws-azure-service"> <h6 class="title" style="margin: 0 0 13px;">AWS & AZURE
                                      </h6> </a>
                                  </div>
                                
  
                              </div> '
                  ><span style="font-size: 14px; color: white"
                    >PRODUCTS & SERVICES</span
                  ></a
                >
              </div>
              <!-- <div class="col-md-2" style="margin-top: 4px; margin-right: 25px">
                  <a href="/feature" style="margin-left: 27px"
                    ><span
                      style="font-size: 14px; color: white; margin-left: 15px"
                      >FEATURES</span
                    ></a
                  >
                </div> -->
              <div class="col-md-2" style="margin-top: 4px">
                <a href="/technology" style="margin-left: -112px"
                  ><span style="font-size: 14px; color: white">TECHNOLOGY</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- footer-bottom -->
</footer>
<!-- footer -->
