<section class="page-section">
    <div class="container">
        <div class="section-title white">
              <h2 class="title">BLOCKCHAIN DECLOPMENT
            </h2>
        </div>
        <div class="row">
            <!-- <div class="col-md-4 col-sm-6 text-center" data-animation="fadeInLeft">
                  <img src="img/sections/mobile-img1.png" width="590" height="415" alt="" />
            </div> -->
            <div class="col-md-12 col-sm-12 text-center" data-animation="fadeInRight">
           <p>Blockchain technology is the game-changer when it comes to transform businesses and business models across a host of industries ranging from banking & financial services, retail, entertainment, supply chain & logistics, healthcare, to government organisations.

        </p>
            
            </div>
        </div>
    </div>
</section>
<br>
<br>
<br>
<br>