import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { HomeComponent } from "./home/home.component";
import { AboutUsComponent } from "./about-us/about-us.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { ProjectComponent } from "./project/project.component";
import { CareerComponent } from "./career/career.component";
import { ServicesComponent } from "./services/services.component";
import { FeaturesComponent } from "./features/features.component";
import { TeamComponent } from "./team/team.component";
import { TechnologyComponent } from "./technology/technology.component";
import { TermsComponent } from "./terms/terms.component";
import { CustomwebdevComponent } from "./customwebdev/customwebdev.component";
import { MobileappComponent } from "./mobileapp/mobileapp.component";
import { BlockchainComponent } from "./blockchain/blockchain.component";
import { InternetMarketingComponent } from "./internet-marketing/internet-marketing.component";
import { SocialMediaComponent } from "./social-media/social-media.component";
import { VideoCreationComponent } from "./video-creation/video-creation.component";
import { LabelDesignComponent } from "./label-design/label-design.component";
import { ServerAndCloudComponent } from "./server-and-cloud/server-and-cloud.component";
import { EmailAndOfficeComponent } from "./email-and-office/email-and-office.component";
import { AwsAndAzureComponent } from "./aws-and-azure/aws-and-azure.component";
import { LogoDesignComponent } from "./logo-design/logo-design.component";
import { CartComponent } from "./cart/cart.component";
import { CheckoutComponent } from "./checkout/checkout.component";

const appRoutes: Routes = [
  { path: "", component: HomeComponent },
  { path: "about-us", component: AboutUsComponent },
  { path: "contact-us", component: ContactUsComponent },
  { path: "project", component: ProjectComponent },
  { path: "career", component: CareerComponent },
  { path: "services", component: ServicesComponent },
  { path: "feature", component: FeaturesComponent },
  { path: "team", component: TeamComponent },
  { path: "technology", component: TechnologyComponent },
  { path: "terms", component: TermsComponent },
  { path: "custom-web-developement", component: CustomwebdevComponent },
  { path: "mobileapp", component: MobileappComponent },
  { path: "Blockchain", component: BlockchainComponent },
  { path: "InternetMarketing", component: InternetMarketingComponent },
  { path: "social-media", component: SocialMediaComponent },
  { path: "video-creation", component: VideoCreationComponent },
  { path: "label-design", component: LabelDesignComponent },
  { path: "server-cloud-service", component: ServerAndCloudComponent },
  { path: "email-offiec365-service", component: EmailAndOfficeComponent },
  { path: "aws-azure-service", component: AwsAndAzureComponent },
  { path: "logo-design", component: LogoDesignComponent },
  { path: "cart", component: CartComponent },
  {path:"checkout", component:CheckoutComponent},

  // otherwise redirect to home
  { path: "**", redirectTo: "" },
];

export const routing = RouterModule.forRoot(appRoutes);
