import { NotificationService } from './../services/notification.service';
import { Component } from '@angular/core';
import { CounterService } from '../services/counter.service';
import { ProductsService } from '../services/products.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent {
  count: number = 0;
  token: any;
  pageLoader: boolean = false;
  cartProducts: any = [];
  removeLoader: boolean = false;
  loadingRemoveProduct: string | null = null;
  constructor(private counter: CounterService, private product: ProductsService, private notify: NotificationService) { 
    this.token=localStorage.getItem('token')
  }
  
  ngOnInit() {
    this.getCount();
    if (this.token) {
      this.getCartItems();
    }
  }

  getCount() {
    this.counter.count.subscribe({
      next: (res: any) => {
        this.count = Number(res);
      }
    })
  }

  getCartItems() {
    this.pageLoader = true;
    this.product.getCartProducts(this.token).subscribe({
      next: (payload: any) => {
        this.pageLoader = false;
        const response = JSON.parse(JSON.stringify(payload));
        if (response.error) {
          this.notify.showError(response.message, '');
        } else {
          this.cartProducts = response.data;
        }
      }, error: (err: any) => {
        this.pageLoader = false;
        this.notify.showError(err.error.message || "Internal Server Error", "");
      }
    })
  }

  AddtoCart() {
    this.IncreaseCount();
  }

  removeFromCart(id: any) {
    this.removeLoader = true;
    this.loadingRemoveProduct = id;
    this.product.removeFromCart(this.token, id).subscribe({
      next: (payload: any) => {
        this.removeLoader = false;
        const response = JSON.parse(JSON.stringify(payload));
        if (response.error) {
          this.notify.showError(response.message, '');
        } else {
          this.notify.showSuccess(response.message, '');
          this.DecreaseCount();
          this.loadingRemoveProduct = null;
          this.cartProducts = response.data;
        }
      }, error: (err: any) => {
        this.removeLoader = false;
        this.notify.showError(err.error.message || "Internal Server Error", "");
      }
    })
  }

  IncreaseCount() {
    this.counter.setCount(++this.count);
  }

  DecreaseCount() {
    this.counter.setCount(--this.count);
  }
}
