<div class="container-fluid" style="margin-top: 100px">
  <div class="row">
    <div class="col-md-8">
      <!-- <img alt="web" src="../../assets/img/m10.png" width="80px" /> -->
      <h2>Web Development Service</h2>
      <p class="text-justify">
        Virtual Gain Technology is your one-stop solution for a high-quality and
        feature-rich website customized to meet your preferences. We deliver
        comprehensive solutions that ensure tangible business outcomes and
        greater customer value. With our proficient web developers and
        designers, we bring your ideas to life.
      </p>
      <p class="text-justify">
        We offer custom web development to address the needs and complexities of
        your business. With the technological expertise and innovation of our
        time, we develop solutions.
      </p>
      <p class="text-justify">
        Get high-performance and result-driven web-based applications for your
        business. We design and develop web applications according to you and
        your project requirements to deliver high-quality and timely delivery.
      </p>
    </div>
    <div class="col-sm-4">
      <img alt="web" src="../../assets/img/Custom-Web-Application.png" />
    </div>
  </div>

  <div class="container" style="margin-top: 100px; margin-bottom: 50px">
    <div class="text-center margin-bottom1">
      <h4>Choose The Best Plan That’s For You</h4>
    </div>
    <div class="row">
      <div class="col-md-4 margin-top1">
        <div class="card text-center">
          <div class="title">
            <i class="fa fa-rocket" aria-hidden="true"></i>
            <h2>{{customweb?.plan_details[0]?.plan}}</h2>
          </div>
          <div class="price">
            <h4>
              ₹ {{customweb?.plan_details[0]?.price}}
              <!-- <span style="font-size: 20px">/month</span> -->
            </h4>
          </div>
          <div class="option">
            <ul>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Mobile Friendly
                Website
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Unlimited Images &
                Videos
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Cost Effective &
                Professional
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>24/7 Support
                (Phone/Email/Chat)
              </li>
            </ul>
          </div>
          <a (click)="AddtoCart(customweb?.plan_details[0])">Add to cart</a>
        </div>
      </div>
      <div class="col-md-4 margin-top1">
        <div class="card text-center">
          <div class="title">
            <i class="fa fa-rocket" aria-hidden="true"></i>
            <h2>{{customweb?.plan_details[1]?.plan}}</h2>
          </div>
          <div class="price">
            <h4>
              ₹ {{customweb?.plan_details[1]?.price}}
              <!-- <span style="font-size: 20px">/month</span> -->
            </h4>
          </div>
          <div class="option">
            <ul>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Mobile Friendly
                Website
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Maintenance
                Included
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Unlimited Images &
                Videos
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Cost Effective &
                Professional
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>24/7 Support
                (Phone/Email/Chat)
              </li>
            </ul>
          </div>
          <a (click)="AddtoCart(customweb?.plan_details[1])">Add to cart</a>
        </div>
      </div>
      <div class="col-md-4 margin-top1">
        <div class="card text-center">
          <div class="title">
            <i class="fa fa-rocket" aria-hidden="true"></i>
            <h2>{{customweb?.plan_details[2]?.plan}}</h2>
          </div>
          <div class="price">
            <h4>
              ₹ {{customweb?.plan_details[2]?.price}}
              <!-- <span style="font-size: 20px">/month</span> -->
            </h4>
          </div>
          <div class="option">
            <ul>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Mobile Friendly
                Website
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Maintenance
                Included
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Unlimited Images &
                Videos
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Cost Effective &
                Professional
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Scale up the
                server infrastructure
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>24/7 Support
                (Phone/Email/Chat)
              </li>
            </ul>
          </div>
          <a (click)="AddtoCart(customweb?.plan_details[2])">Add to cart</a>
        </div>
      </div>
    </div>
  </div>
</div>
