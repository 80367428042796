
		  
		  <section id="team" class="page-section">
            <div class=" content-in fixed"></div>
            <div class="container text-center white">
               <div class="section-title" data-animation="fadeInUp">
                  <h2 class="title">Meet the Team</h2>
               </div>
               <div class="row">
                  <div class="owl-carousel white navigation-1" data-pagination="false" data-items="4" data-autoplay="true" data-navigation="true" data-animation="fadeInUp">
                     <div class="col-sm-4 col-md-3 icons-hover-color bottom-xs-pad-20">
                        <div class="image">
                            <!-- Image -->
                            <img src="assets/img/avatar.png" alt="" title="" width="150" height="150">
                         </div>
                        <div class="description">
                           <!-- Name -->
                           <h4 class="name">Phillip Parisis</h4>
                           <!-- Designation -->
                           <div class="role">Founder</div>
                           <!-- Text -->
                           <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those intereste.</p>
                        </div>
                        <div class="social-icon i-3x">
                           <!-- Social Icons -->
                           <a href="#"><i class="fa fa-facebook"></i></a>
                           <a href="#"><i class="fa fa-twitter"></i></a>
                           <a href="#"><i class="fa fa-google"></i></a>
                           <a href="#"><i class="fa fa-pinterest"></i></a>
                        </div>
                     </div>
                     <!-- .employee	-->
                     <div class="col-sm-4 col-md-3 icons-hover-color bottom-xs-pad-20">
                        <div class="image">
                            <!-- Image -->
                            <img src="assets/img/avatar.png" alt="" title="" width="150" height="150">
                         </div>
                        <div class="description">
                           <!-- Name -->
                           <h4 class="name">Simo Kruyt</h4>
                           <!-- Designation -->
                           <div class="role">Analyst</div>
                           <!-- Text -->
                           <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those intereste.</p>
                        </div>
                        <div class="social-icon i-3x">
                           <!-- Social Icons -->
                           <a href="#"><i class="fa fa-facebook"></i></a>
                           <a href="#"><i class="fa fa-twitter"></i></a>
                           <a href="#"><i class="fa fa-google"></i></a>
                           <a href="#"><i class="fa fa-pinterest"></i></a>
                        </div>
                     </div>
                     <!-- .employee -->
                     <div class="col-sm-4 col-md-3 icons-hover-color bottom-xs-pad-20">
                        <div class="image">
                            <!-- Image -->
                            <img src="assets/img/avatar.png" alt="" title="" width="150" height="150">
                         </div>
                        <div class="description">
                           <!-- Name -->
                           <h4 class="name">Jorge Canaveral</h4>
                           <!-- Designation -->
                           <div class="role">Graphic designer</div>
                           <!-- Text -->
                           <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those intereste.</p>
                        </div>
                        <div class="social-icon i-3x">
                           <!-- Social Icons -->
                           <a href="#"><i class="fa fa-facebook"></i></a>
                           <a href="#"><i class="fa fa-twitter"></i></a>
                           <a href="#"><i class="fa fa-google"></i></a>
                           <a href="#"><i class="fa fa-pinterest"></i></a>
                        </div>
                     </div>
                     <!-- .employee -->
                     <div class="col-sm-4 col-md-3 icons-hover-color bottom-xs-pad-20">
                        <div class="image">
                            <!-- Image -->
                            <img src="assets/img/avatar.png" alt="" title="" width="150" height="150">
                         </div>
                        <div class="description">
                           <!-- Name -->
                           <h4 class="name">Aimee Devlin</h4>
                           <!-- Designation -->
                           <div class="role">Developer</div>
                           <!-- Text -->
                           <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those intereste.</p>
                        </div>
                        <div class="social-icon i-3x">
                           <!-- Social Icons -->
                           <a href="#"><i class="fa fa-facebook"></i></a>
                           <a href="#"><i class="fa fa-twitter"></i></a>
                           <a href="#"><i class="fa fa-google"></i></a>
                           <a href="#"><i class="fa fa-pinterest"></i></a>
                        </div>
                     </div>
                     <div class="col-sm-4 col-md-3 icons-hover-color bottom-xs-pad-20">
                        <div class="image">
                            <!-- Image -->
                            <img src="assets/img/avatar.png" alt="" title="" width="150" height="150">
                         </div>
                        <div class="description">
                           <!-- Name -->
                           <h4 class="name">Phillip Parisis</h4>
                           <!-- Designation -->
                           <div class="role">Founder</div>
                           <!-- Text -->
                           <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those intereste.</p>
                        </div>
                        <div class="social-icon i-3x">
                           <!-- Social Icons -->
                           <a href="#"><i class="fa fa-facebook"></i></a>
                           <a href="#"><i class="fa fa-twitter"></i></a>
                           <a href="#"><i class="fa fa-google"></i></a>
                           <a href="#"><i class="fa fa-pinterest"></i></a>
                        </div>
                     </div>
                     <!-- .employee	-->
                     <div class="col-sm-4 col-md-3 icons-hover-color bottom-xs-pad-20">
                        <div class="image">
                           <!-- Image -->
                           <img src="assets/img/avatar.png" alt="" title="" width="150" height="150">
                        </div>
                        <div class="description">
                           <!-- Name -->
                           <h4 class="name">Simo Kruyt</h4>
                           <!-- Designation -->
                           <div class="role">Analyst</div>
                           <!-- Text -->
                           <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those intereste.</p>
                        </div>
                        <div class="social-icon i-3x">
                           <!-- Social Icons -->
                           <a href="#"><i class="fa fa-facebook"></i></a>
                           <a href="#"><i class="fa fa-twitter"></i></a>
                           <a href="#"><i class="fa fa-google"></i></a>
                           <a href="#"><i class="fa fa-pinterest"></i></a>
                        </div>
                     </div>
                     <!-- .employee -->
                  </div>
               </div>
            </div>
         </section>
         <!-- team -->
         <br>
         <br>