<section id="about-us" class="page-section" data-animation="fadeInUp">
    <div class="container">
        <div class="section-title" data-animation="fadeInUp">
            <h2 class="title">ABOUT US</h2>
        </div>
        <div class="row">
            <div class="col-md-8 col-md-offset-2 text-center" data-animation="pulse">
                <!-- Text -->
                <p class="title-description">EVERYTHING YOU NEED TO KNOW ABOUT OUR COMPANY
                </p>
            </div>
        </div>
        <div class="row">
            <div data-appear-animation="fadeInLeft" class="col-md-4 text-center appear-animation fadeInLeft appear-animation-visible">
                <!-- Image -->
                <img width="300" height="300" alt="" src="assets/img/sections/about/about1.png">
            </div>
            <div class="col-md-8">
                <div class="section-title text-left" data-animation="fadeInUp">
                    <!-- Title -->
                    <h2 class="title">Who are we</h2>
                </div>
                <!-- Content -->
  <div data-animation="fadeInDown">
    <p>We are a team of young, talented and skilful web developers and design engineers who meticulously create great websites and designs! We provide professional and customised solutions to our clients that help them market their products to a wider audience.
    </p>
   <P>We are experts in web designing, developing, branding, optimising, and creating apps and softwares for wide range of clients. Apart from web development, we deliver complete suite of digital services. We start from creative design and conceptualisation to strategy and planning, we work closely with our clients so that they focus on business and we get their digital developments covered.</P>
   <P>Our experienced team of developers are armed with latest and advanced technologies and constantly upgrade to use cutting edge technologies for our clients. 
   </P>
</div>
               
            </div>
        </div>

        <div class="row">
              <div class="col-md-8">
                <div class="section-title text-left" data-animation="fadeInUp">
                    <!-- Title -->
                    <h2 class="title">Why Work with us</h2>
                </div>
                <!-- Content -->
                <div data-animation="fadeInDown">
                    <P>Our innovative web solutions developed using tried and proved strategies to customise and reduce costs allows us to reduce overall business expenses  without sacrificing the quality, features and functionality for our clients. </P>
                    <P>We thrive for building long term relationships with our clients by  delivering quality web solutions, meeting our timely delivery commitments and providing all digital solutions.</P>  

                </div>
              
            </div>

            <div data-appear-animation="fadeInLeft" class="col-md-4 text-center appear-animation fadeInLeft appear-animation-visible">
                <!-- Image -->
                <img width="960" height="960" alt="" src="assets/img/sections/about/about2.jpeg">
            </div>

        </div>


    </div>
</section>
<br>
<br>
<br>