
		
        <!-- page-header -->
        <section id="service" class="page-section">
            <div class="container">
                <div class="section-title">
                    <!-- Heading -->
                    <h2 class="title">Our Services</h2>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="row text-center top-pad-30">
                            <div class="col-sm-4 col-md-4">
                                <i class="icon-leaf3 medium text-color fa-2x icons-circle border-color"></i>
                                <h4>Creative Design</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas dapibus facilisis cvallis.</p>
                            </div>
                            <div class="col-sm-4 col-md-4">
                                <i class="icon-mobile6 medium text-color fa-2x icons-circle border-color"></i>
                                <h4>100% responsive</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas dapibus facilisis cvallis.</p>
                            </div>
                            <div class="col-sm-4 col-md-4">
                                <i class="icon-html52 medium text-color fa-2x icons-circle border-color"></i>
                                <h4>HTML5</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas dapibus facilisis cvallis.</p>
                            </div>
                        </div>
                        <div class="row text-center top-pad-30">
                            <div class="col-sm-4 col-md-4">
                                <i class="icon-css3 medium text-color fa-2x icons-circle border-color"></i>
                                <h4>CSS3</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas dapibus facilisis cvallis.</p>
                            </div>
                            <div class="col-sm-4 col-md-4">
                                <i class="icon-wordpress medium text-color fa-2x icons-circle border-color"></i>
                                <h4>Wordpress</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas dapibus facilisis cvallis.</p>
                            </div>
                            <div class="col-sm-4 col-md-4">
                                <i class="icon-joomla medium text-color fa-2x icons-circle border-color"></i>
                                <h4>Joomla</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas dapibus facilisis cvallis.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- page-section -->
        <br>
        <br>
        <br>