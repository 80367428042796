import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  BASE_URL: string = environment.API_URL;
  constructor(private http: HttpClient) {}

  onLogin(body: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}/vgt/user/auth/api/v1/login`, body);
  }
}
