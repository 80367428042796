<section id="features" class="page-section">
  <div
    class="image-bg content-in fixed"
    data-background="assets/img/sections/about/tech1.png"
  >
    <div class="overlay-strips"></div>
  </div>
  <div class="container">
    <div class="section-title white">
      <!-- Heading -->
      <h2 class="title">Technology</h2>
    </div>
    <div class="row white">
      <div class="item-box icons-color hover-white col-sm-6 col-md-3">
        <a href="#">
          <i class="icon-perm-data-setting fa-2x"></i>
          <h5 class="title">HTML5</h5>
          <i class="icon-perm-data-setting fa-2x"></i>
          <h5 class="title">CSS3</h5>
          <i class="icon-perm-data-setting fa-2x"></i>
          <h5 class="title">BOOTSTRAP</h5>
          <i class="icon-perm-data-setting fa-2x"></i>
          <h5 class="title">ANGULAR</h5>
          <i class="icon-perm-data-setting fa-2x"></i>
          <h5 class="title">REACT</h5>
          <i class="icon-perm-data-setting fa-2x"></i>
          <h5 class="title">DOCKER & KUBERNETES</h5>
        </a>
      </div>

      <div class="item-box icons-color hover-white col-sm-6 col-md-3">
        <a href="#">
          <!-- <i class="icon-perm-data-setting fa-2x"></i> 
                          <h5 class="title">Wordpress Development</h5> -->
          <i class="icon-perm-data-setting fa-2x"></i>
          <h5 class="title">NODE JS</h5>
          <!-- <i class="icon-perm-data-setting fa-2x"></i> 
                           <h5 class="title">GOLANG</h5> -->
          <i class="icon-perm-data-setting fa-2x"></i>
          <h5 class="title">MONGODB</h5>
          <!-- <i class="icon-perm-data-setting fa-2x"></i> 
                           <h5 class="title">BLOCKCHAIN</h5> -->
          <i class="icon-perm-data-setting fa-2x"></i>
          <h5 class="title">MICROSERVICES</h5>
        </a>
      </div>
      <!-- <div class="item-box icons-color hover-white col-sm-6 col-md-3">
                          <a href="#">
                           <i class="icon-perm-data-setting fa-2x"></i> 
                           <h5 class="title">PHP Development</h5>
                           <i class="icon-perm-data-setting fa-2x"></i> 
                           <h5 class="title">CakePHP</h5>
                           <i class="icon-perm-data-setting fa-2x"></i> 
                           <h5 class="title">JQuery Websites</h5>
                           
                          </a>
                      </div> -->
      <div class="item-box icons-color hover-white col-sm-6 col-md-3">
        <a href="#">
          <i class="icon-perm-data-setting fa-2x"></i>
          <h5 class="title">ANDROID DEVELOPMENT</h5>
          <i class="icon-perm-data-setting fa-2x"></i>
          <h5 class="title">IOS DEVELOPMENT</h5>
          <i class="icon-perm-data-setting fa-2x"></i>
          <h5 class="title">GRAPHIC DESIGNING</h5>
          <i class="icon-perm-data-setting fa-2x"></i>
          <h5 class="title">ERP SOLUTIONS</h5>
        </a>
      </div>
    </div>
    <br />
    <br />
    <br />
  </div>
</section>
