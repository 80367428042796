<section id="features" class="page-section">
    <div class=" content-in fixed">
        <div class="overlay-strips"></div>
    </div>
    <div class="container">
        <div class="section-title white">
            <!-- Heading -->
            <h2 class="title">Awesome Features</h2>
        </div>
        <div class="row white">
            <div class="item-box icons-color hover-white col-sm-6 col-md-4">
                <a href="#">
                <!-- Icon -->
                <i class="icon-perm-data-setting fa-2x"></i> 
                <!-- Title -->
                <h5 class="title">Quality</h5>
                <!-- Text -->
                <div>
                    A good web design company knows how to strike a balance between the costs of services offered and end quality delivered.               
                </div></a>
            </div>
            <div class="item-box icons-color hover-white col-sm-6 col-md-4">
                <a href="#">
                
                <i class="icon-mobile5 fa-2x"></i> 
              
                <h5 class="title">Mobile Compatibility</h5>
               
                <div>As more and more people use their mobile phones to access the Internet, creating a mobile optimized website has become a necessity.</div></a>
           
            </div>
            <div class="item-box icons-color hover-white col-sm-6 col-md-4">
                <a href="#">
                <i class="icon-html53 fa-2x"></i> 
                <h5 class="title">Accessible to All Users</h5>
                 <div>A user-friendly website should also be accessible to everyone including blind, disabled or the elderly. These users typically use screen-readers to access the Internet. </div></a>
            </div>
            <div class="item-box icons-color hover-white col-sm-6 col-md-4">
                <a href="#">
                <i class="icon-css32 fa-2x"></i> 
                  <h5 class="title">Well Planned Information Architecture</h5>
                  <div>How information is organised and presented on your website is vital for good usability. It has become even more important today as websites offer a wide range of information and resources to attract their target market. </div></a>
            </div>
            <div class="item-box icons-color hover-white col-sm-6 col-md-4">
                <a href="#">
                <i class="icon-magic-wand fa-2x"></i> 
                 <h5 class="title">Well-Formatted Content Easy to Scan</h5>
                 <div>The average Internet user skims through the content on a web page instead of reading each and every word from top to down. Users tend to scan through key parts of the page quickly to determine if it is relevant to their needs.</div></a>
            </div>
            <div class="item-box icons-color hover-white col-sm-6 col-md-4">
                <a href="#">
                <i class="icon-add-shopping-cart fa-2x"></i> 
                 <h5 class="title">Web Compatibility</h5>
                <div>The website must be compatible to run across for different platforms and browsers and devices. Your site must be well-built and display easily across different screen size and resolutions.</div></a>
            </div>
            <div class="item-box icons-color hover-white col-sm-6 col-md-4">
                <a href="#">
                 <i class="icon-power-cord fa-2x"></i> 
                  <h5 class="title">Valid Mark-Up & Clean Code</h5>
                  <div>A website that adheres to the relevant web design best practices and standards is often more robust and dependable. 
                </div></a>
            </div>
              
        </div>
    </div>
</section>
<br>
<br>
<br>