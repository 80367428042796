<!-- <div class="page-header">
    <div class="container">
        <h1 class="title">Contact Us</h1>
    </div>
    <div class="breadcrumb-box">
        <div class="container">
            <ul class="breadcrumb">
                <li><a href="index.html">Home</a></li>
                <li><a href="#">Pages</a></li>
                <li class="active">Contact Us</li>
            </ul>
        </div>
    </div>
</div> -->
<!-- page-header -->
<section id="contact-us" class="page-section">
  <div
    class="image-bg content-in fixed"
    data-background="assets/img/sections/about/conbc.jpeg"
  >
    <div class="overlay-strips"></div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-md-12 contact-info">
        <div class="row text-center">
          <address class="col-sm-4 col-md-4">
            <i
              class="fa fa-map-marker i-9x icons-circle text-color light-bg hover-black"
            ></i>
            <div class="title">Address</div>
            218 ,10 Biz Park , Viman nagar, Pune, Maharashtra, PIN-411014
          </address>
          <address class="col-sm-4 col-md-4">
            <i
              class="fa fa-microphone i-9x icons-circle text-color light-bg hover-black"
            ></i>
            <div class="title">Phone</div>
            <div>+ 91 9922011055</div>
          </address>
          <address class="col-sm-4 col-md-4">
            <i
              class="fa fa-envelope i-9x icons-circle text-color light-bg hover-black"
            ></i>
            <div class="title">Support</div>
            <div>support@virtualgaintechnologies.com</div>
          </address>
        </div>
      </div>
    </div>
    <hr class="pad-10" />
    <div class="row">
      <div class="col-md-4">
        <p class="form-message" style="display: none"></p>
        <div class="contact-form">
          <!-- Form Begins -->
          <form
            role="form"
            name="contactform"
            id="contactform"
            method="post"
            action="php/contact-form.php"
          >
            <!-- Field 1 -->
            <div class="input-text form-group">
              <input
                type="text"
                name="contact_name"
                class="input-name form-control"
                placeholder="Full Name"
              />
            </div>
            <!-- Field 2 -->
            <div class="input-email form-group">
              <input
                type="email"
                name="contact_email"
                class="input-email form-control"
                placeholder="Email"
              />
            </div>
            <!-- Field 3 -->
            <div class="input-email form-group">
              <input
                type="text"
                name="contact_phone"
                class="input-phone form-control"
                placeholder="Phone"
              />
            </div>
            <!-- Field 4 -->
            <div class="textarea-message form-group">
              <textarea
                name="contact_message"
                class="textarea-message hight-82 form-control"
                placeholder="Message"
                rows="2"
              ></textarea>
            </div>
            <!-- Button -->
            <button
              class="btn btn-default btn-block"
              type="submit"
              style="background-color: #4b78af; margin-bottom: 20px"
            >
              Send Now <i class="icon-paper-plane"></i>
            </button>
          </form>
          <!-- Form Ends -->
        </div>
      </div>

      <div class="col-md-8">
        <div class="map-section">
          <!-- <div id="Map" class="map height-300"> -->
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3781.9741170614984!2d73.9098664!3d18.5752056!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c147b8b70951%3A0x8614c143f0b9c8c!2s10%20Biz%20Park!5e0!3m2!1sen!2sin!4v1703844562783!5m2!1sen!2sin"
            width="600"
            height="450"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            width="100%"
            height="300"
            frameborder="0"
            style="border: 0"
            allowfullscreen
          ></iframe>

          <!-- </div> -->
        </div>
      </div>
      <!-- map -->
    </div>
  </div>
</section>
<!-- page-section -->
<br />
<br />
<!-- <br>
  <br> -->
