import { Component, OnInit } from '@angular/core';
import { CounterService } from '../services/counter.service';
import { ProductsService } from '../services/products.service';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-internet-marketing',
  templateUrl: './internet-marketing.component.html',
  styleUrls: ['./internet-marketing.component.css']
})
export class InternetMarketingComponent implements OnInit {
  count: number = 0;
  MarketingData: any = [];
  token: any;
  constructor(private counter: CounterService, private product: ProductsService, private notify: NotificationService) { 
    this.token = localStorage.getItem('token');
  }
  
  ngOnInit() {
    this.getCount();
    this.getAllProducts();
  }

  getCount() {
    this.counter.count.subscribe({
      next: (res: any) => {
        this.count = Number(res);
      }
    })
  }

  getAllProducts() {
    this.product.getAllProducts().subscribe({
      next: (payload: any) => {
        const response = JSON.parse(JSON.stringify(payload));
        if (response.error) {
          this.notify.showError(response.message, "");
        } else {
          this.MarketingData = response.data[2];
        }
      },
      error: (err: any) => {
        this.notify.showError(err.error.message || "Internal Server Error", "");
      },
    });
  }

  AddtoCart(plan:any) {
    const body = {
      product_details: this.MarketingData.product_details,
      plan: plan.plan,
      price:plan.price
    }
    this.product.addtoCart(this.token, body).subscribe({
      next: (payload: any) => {
        const response = JSON.parse(JSON.stringify(payload));
        if (response.error) {
          this.notify.showError(response.message, '');
        } else {
          this.notify.showSuccess(response.message, '');
          this.IncreaseCount();
        }
      }, error: (err: any) => {
        if (err.error.message == 'Unauthorised!!') {
          this.notify.showError(err.error.message+ " Please Login" || "Internal Server Error", "");
        } else {
          this.notify.showError(err.error.message || "Internal Server Error", "");
        } 
      }  
    })
  }

  IncreaseCount() {
    this.counter.setCount(++this.count);
  }

}
