
        <section id="page-content" class="page-section">
            <div class="image-bg content-in fixed" data-background="assets/img/sections/about/career.jpeg">
                <div class="overlay-strips"></div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-md-7">
                     
                       
                        <div class="panel-group list-style" id="accordion">
                            <div class="panel panel-default">
                                <div class="panel-heading">
                                    <div class="panel-title" style="font-size: 23px;">
                                        <a data-toggle="collapse" data-parent="#accordion" href="#collapse1">Work with us</a>
                                    </div>
                                </div>
                                <div id="collapse1" class="panel-collapse collapse in">
                                    <div class="panel-body">
                                       <p>As a technology leader, we are thriving towards building up an image of our company by delivering outstanding quality services and assistance to our clients and partners. We feel proud when our work defines our success and excellence which have been published in admired places for Tech News on internet.</p>
                                        <ol style="font-size: 17px;">
                                            <li>An Inspiring Work Culture</li>
                                            <li>Flexible Working Hours</li>
                                            <li>Set Your Own Deadline</li>
                                            <li>Learn Like Never Before</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="panel panel-default panel-bg graphic-design">
                                <div class="panel-heading">
                                    <div class="panel-title">
                                        <a data-toggle="collapse" data-parent="#accordion" href="#collapse2">What we Expect from you?</a>
                                    </div>
                                </div>
                                <div id="collapse2" class="panel-collapse collapse">
                                    <div class="panel-body">
                                        <p>Ultrices metus et dui dictum quis elementum augue imperdiet. Nunc a turpis nec elit porttitor varius. Cras non felis eget nibh eleifend rhoncus id sit amet nisi. Cras est mi, dapibus eu dictum quis, interdum in dui. Ut lacinia accumsan libero ac semper. Sed in turpis in massa mattis blandit eget vel sem. Nullam id erat sapien, ac faucibus est. Ut eu tortor rutrum erat pellentesque iaculis. Nunc porta convallis pharetra.</p>
                                        <ol>
                                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                                            <li>Mauris dictum tempor magna, sit amet venenatis elit sodales sagittis.</li>
                                            <li>Pellentesque rhoncus arcu sed nisl vulputate ultrices.</li>
                                            <li>In eget dolor nec tortor tempor blandit.</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div class="panel panel-default panel-bg web-design">
                                <div class="panel-heading">
                                    <div class="panel-title">
                                        <a data-toggle="collapse" data-parent="#accordion" href="#collapse3">What you&#39;ve got?</a>
                                    </div>
                                </div>
                                <div id="collapse3" class="panel-collapse collapse">
                                    <div class="panel-body">
                                        <p>Ultrices metus et dui dictum quis elementum augue imperdiet. Nunc a turpis nec elit porttitor varius. Cras non felis eget nibh eleifend rhoncus id sit amet nisi. Cras est mi, dapibus eu dictum quis, interdum in dui. Ut lacinia accumsan libero ac semper. Sed in turpis in massa mattis blandit eget vel sem. Nullam id erat sapien, ac faucibus est. Ut eu tortor rutrum erat pellentesque iaculis. Nunc porta convallis pharetra.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="panel panel-default panel-bg ecommerce">
                                <div class="panel-heading">
                                    <div class="panel-title">
                                        <a data-toggle="collapse" data-parent="#accordion" href="#collapse4">Other Informations</a>
                                    </div>
                                </div>
                                <div id="collapse4" class="panel-collapse collapse">
                                    <div class="panel-body">
                                        <p>Ultrices metus et dui dictum quis elementum augue imperdiet. Nunc a turpis nec elit porttitor varius. Cras non felis eget nibh eleifend rhoncus id sit amet nisi. Cras est mi, dapibus eu dictum quis, interdum in dui. Ut lacinia accumsan libero ac semper. Sed in turpis in massa mattis blandit eget vel sem. Nullam id erat sapien, ac faucibus est. Ut eu tortor rutrum erat pellentesque iaculis. Nunc porta convallis pharetra.</p>
                                    </div>
                                </div>
                            </div> -->


                        </div>
                       
                    </div>
                    <div class="col-sm-12 col-md-5">
                        <div class="career-form">
							<form role="form" class="form-box" name="careerform" id="careerform" method="post" action="php/career.php">
							<h3 class="title">Apply Now</h3>
							 <p class="form-message" style="display: none;"></p>
						   <div class="input-text form-group">
							<input class="input-name form-control" type="text" name="career_name" placeholder="Name" /> 
							</div>
							<div class="input-email form-group">
							<input class="input-email form-control" type="email" name="career_email" placeholder="Email" />
							</div>
							<div class="row" role="form">
                            	<div class="col-md-6">
								<label class="sr-only">City</label> 
                                <input type="text" name="career_city" class="form-control" placeholder="City" />
                               </div>
                               <div class="col-md-6">
								<label class="sr-only">Age</label> 
                                <input class="input-phone form-control" type="text" name="career_phone" placeholder="Phone" />
                                </div>

							</div>
							<div class="input-phone form-group">
							</div>
							<div class="row" role="form">
								<div class="col-md-6">
								<label class="sr-only">Salary</label> 
								<input type="text" class="form-control" name="career_salary" placeholder="Expected Salary" /></div>
								<div class="col-md-6">
								<label class="sr-only">Experience</label> 
								<input type="text" class="form-control" name="career_experience" placeholder="Experience" /></div>
							</div>
								<textarea class="form-control" name="carrer_comment" placeholder="Other Details"></textarea> 
							<div class="input-file form-group">
							<input type="file" name="career_file" class="input-file" />
							</div>	
							<div class="clearfix"></div>
							<button class="btn btn-default" type="submit" style="background-color: #4b78af;">Submit</button>
						  </form>
						</div> 
                    </div>
                </div>
            </div>
        </section>
        <!-- page-section -->
     <br>
     <br>