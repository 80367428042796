<div class="container-fluid" style="margin-top: 100px">
  <div class="row">
    <div class="col-md-8">
      <!-- <img alt="web" src="../../assets/img/m10.png" width="80px" /> -->
      <h2>Windows & Linux VPS Server</h2>
      <p class="text-justify">
        Fully Managed Windows VPS Server Hosting for Your Business Website in
        India
      </p>
      <p class="text-justify">
        Highest degree of protection, unlimited 1Gbps data transfer, NVME
        Storage
      </p>
      <p class="text-justify">
        Get the best managed Linux vps hosting for website hosting in India with
        99.99% uptime, 1GBPS network and unlimited bandwidth
      </p>
      <p class="text-justify">
        Our servers are fast and secure. so you can focus on your business
        without worrying about server maintenance.
      </p>
    </div>
    <div class="col-sm-4">
      <img alt="web" src="../../assets/img/vps-server.jpg" />
    </div>
  </div>

  <div class="container" style="margin-top: 100px; margin-bottom: 50px">
    <div class="text-center margin-bottom1">
      <h4>Choose The Best Plan That’s For You</h4>
    </div>
    <div class="row">
      <div class="col-md-3 margin-top1">
        <div class="card text-center">
          <div class="title">
            <i class="fa fa-rocket" aria-hidden="true"></i>
            <h2>Basic</h2>
          </div>
          <div class="price">
            <h4>₹ 2,599<span style="font-size: 20px">/month</span></h4>
          </div>
          <div class="option">
            <ul>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Intel(R) Xeon(R)
                Gold Dual 6226 R &#64; 2.90 Ghz 4 Core Processor
              </li>
              <li><i class="fa fa-check" aria-hidden="true"></i>4 GB RAM</li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>100 GB NVME
                Storage
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>1 Ip Address
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>1 GBPS Uplink
                Bandwidth
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Managed Services
              </li>
            </ul>
          </div>
          <a (click)="AddtoCart(serverData?.plan_details[0])">Add to cart</a>
        </div>
      </div>
      <div class="col-md-3 margin-top1">
        <div class="card text-center mt-5">
          <div class="title">
            <i class="fa fa-rocket" aria-hidden="true"></i>
            <h2>Standard</h2>
          </div>
          <div class="price">
            <h4>₹ 4,199<span style="font-size: 20px">/month</span></h4>
          </div>
          <div class="option">
            <ul>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Intel(R) Xeon(R)
                Gold Dual 6226 R &#64; 2.90 Ghz 4 Core Processor
              </li>
              <li><i class="fa fa-check" aria-hidden="true"></i>8 GB RAM</li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>200 GB NVME
                Storage
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>1 Ip Address
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>1 GBPS Uplink
                Bandwidth
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Managed Services
              </li>
            </ul>
          </div>
          <a (click)="AddtoCart(serverData?.plan_details[1])">Add to cart</a>
        </div>
      </div>
      <div class="col-md-3 margin-top1">
        <div class="card text-center">
          <div class="title">
            <i class="fa fa-rocket" aria-hidden="true"></i>
            <h2>Advance</h2>
          </div>
          <div class="price">
            <h4>₹ 5,199<span style="font-size: 20px">/month</span></h4>
          </div>
          <div class="option">
            <ul>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>ntel(R) Xeon(R)
                Gold Dual 6226 R &#64; 2.90 Ghz 6 Core Processor
              </li>
              <li><i class="fa fa-check" aria-hidden="true"></i>16 GB RAM</li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>500 GB NVME
                Storage
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>1 Ip Address
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>1 GBPS Uplink
                Bandwidth
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>1 Free SSL
                Certificate
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Managed Services
              </li>
            </ul>
          </div>
          <a (click)="AddtoCart(serverData?.plan_details[2])">Add to cart</a>
        </div>
      </div>
      <div class="col-md-3 margin-top1">
        <div class="card text-center">
          <div class="title">
            <i class="fa fa-rocket" aria-hidden="true"></i>
            <h2>Premium</h2>
          </div>
          <div class="price">
            <h4>₹ 7,999<span style="font-size: 20px">/month</span></h4>
          </div>
          <div class="option">
            <ul>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Intel(R) Xeon(R)
                Gold Dual 6226 R &#64; 2.90 Ghz 8 Core Processor
              </li>
              <li><i class="fa fa-check" aria-hidden="true"></i>32 GB RAM</li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>500 GB NVME
                Storage
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>1 Ip Address
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>1 GBPS Uplink
                Bandwidth
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>1 Free SSL
                Certificate
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>Managed Services
              </li>
            </ul>
          </div>
          <a (click)="AddtoCart(serverData?.plan_details[3])">Add to cart</a>
        </div>
      </div>
    </div>
  </div>
</div>
