import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ProductsService {
  BASE_URL: string = environment.API_URL;

  constructor(private http: HttpClient) {}

  getAllProducts(): Observable<any> {
    return this.http.get(
      `${this.BASE_URL}/vgt/product/api/v1/get-all-products`
    );
  }

  // cart section

  addtoCart(token: string, body: any): Observable<any> {
    return this.http.post(
      `${this.BASE_URL}/vgt/cart/api/v1/add-product/${token}`,
      body
    );
  }

  getCartProducts(token: string): Observable<any> {
    return this.http.get(
      `${this.BASE_URL}/vgt/cart/api/v1/get-user-cart/${token}`
    );
  }

  removeFromCart(token: string, id: any) {
    return this.http.put(
      `${this.BASE_URL}/vgt/cart/api/v1/remove-product/${token}/${id}`,
      ""
    );
  }
}
