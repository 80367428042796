import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { routing }  from './app.routing';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ProjectComponent } from './project/project.component';
import { CareerComponent } from './career/career.component';
import { ServicesComponent } from './services/services.component';
import { FeaturesComponent } from './features/features.component';
import { TeamComponent } from './team/team.component';
import { TechnologyComponent } from './technology/technology.component';
import { TermsComponent } from './terms/terms.component';
import { CustomwebdevComponent } from './customwebdev/customwebdev.component';
import { MobileappComponent } from './mobileapp/mobileapp.component';
import { BlockchainComponent } from './blockchain/blockchain.component';
import { InternetMarketingComponent } from './internet-marketing/internet-marketing.component';
import { SocialMediaComponent } from './social-media/social-media.component';
import { VideoCreationComponent } from './video-creation/video-creation.component';
import { LabelDesignComponent } from './label-design/label-design.component';
import { AwsAndAzureComponent } from './aws-and-azure/aws-and-azure.component';
import { EmailAndOfficeComponent } from './email-and-office/email-and-office.component';
import { LogoDesignComponent } from './logo-design/logo-design.component';
import { ServerAndCloudComponent } from './server-and-cloud/server-and-cloud.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CartComponent } from './cart/cart.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';


@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    HomeComponent,
    AboutUsComponent,
    ContactUsComponent,
    ProjectComponent,
    CareerComponent,
    ServicesComponent,
    FeaturesComponent,
    TeamComponent,
    TechnologyComponent,
    TermsComponent,
    CustomwebdevComponent,
    MobileappComponent,
    BlockchainComponent,
    InternetMarketingComponent,
    SocialMediaComponent,
    VideoCreationComponent,
    LabelDesignComponent,
    AwsAndAzureComponent,
    EmailAndOfficeComponent,
    LogoDesignComponent,
    ServerAndCloudComponent,
    CartComponent,
    CheckoutComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    RouterModule,
    CommonModule,
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      preventDuplicates: true,
      closeButton: true,
      extendedTimeOut: 5000,
      progressBar: true,
      progressAnimation: 'decreasing',
      positionClass: 'toast-top-right',
      maxOpened: 1,
  }),

    routing
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
