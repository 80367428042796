import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CounterService {
  private initialValue = localStorage.getItem("initialCount");
  private number: any = new BehaviorSubject(
    this.initialValue ? this.initialValue : "0"
  );
  public count: Observable<any> = this.number.asObservable();
  constructor() {}

  setCount(count: any) {
    this.number.next(count);
    localStorage.setItem("initialCount", count);
  }
}
