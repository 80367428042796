import { AuthService } from "./../services/auth.service";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CounterService } from "../services/counter.service";
import { NotificationService } from "../services/notification.service";
import { Router } from "@angular/router";
import { ProductsService } from "../services/products.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  @ViewChild('myModalClose') modalClose2;
  loginForm: FormGroup;
  token: any;
  loader: boolean = false;
  count: number = 0;
  emailId: string;
  cartProducts: any = [];
  constructor(
    private fb: FormBuilder,
    private counter: CounterService,
    private auth: AuthService,
    private notify: NotificationService,
    private route: Router,
    private product:ProductsService
  ) {
    this.token = localStorage.getItem('token');
    this.emailId = localStorage.getItem('email');
  }

  ngOnInit() {
    this.createLoginform();
    this.getCount();
  }


  createLoginform() {
    this.loginForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
    });
  }


  getCartItems() {
    this.product.getCartProducts(this.token).subscribe({
      next: (payload: any) => {
        const response = JSON.parse(JSON.stringify(payload));
        if (response.error) {
          this.notify.showError(response.message, '');
        } else {
          this.cartProducts = response.data;
          this.setCount();
        }
      }, error: (err: any) => {
        this.notify.showError(err.error.message || "Internal Server Error", "");
      }
    })
  }

  onLogin() {
    this.loader = true;
    const body = {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password,
    };
    this.emailId = body.email;
    localStorage.setItem('email',body?.email)
    this.auth.onLogin(body).subscribe({
      next: (res: any) => {
        this.loader = false;
        const response = JSON.parse(JSON.stringify(res));
        if (response.error) {
          this.notify.showError(response.message, "");
        } else {
          this.notify.showSuccess(response.message, "");
          this.token = response.data;
          this.getCartItems();
          localStorage.setItem("token", response.data);
          this.modalClose2.nativeElement.click();
          window.location.reload();
        }
      },
      error: (err) => {
        this.loader = false;
        this.notify.showError(err.error.message || "Internal Server Error", "");
      },
    });
  }

  getCount() {
    this.counter.count.subscribe({
      next: (res: any) => {
        this.count = res;
      },
    });
  }

  setCount() {
    this.counter.setCount(this.cartProducts.products.length);
  }

  onLogout() {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    window.location.reload();
  }
}
