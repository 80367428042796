<div class="container" *ngIf="!pageLoader">
  <div class="row col-12">
    <br />
    <h2>Cart</h2>
    <strong>{{ count }} items</strong> in cart
    <div class="flexbox" *ngIf='cartProducts?.products.length'>
      <div class="left">
        <div class="card">
          <div class="header row">
            <div class="col-md-8">Product</div>
            <div class="col-md-2">Price</div>
            <div class="col-md-2">Remove</div>
          </div>
          <hr />
          <div class="body row listedItem" *ngFor="let Product of cartProducts?.products">
            <div class="col-md-8">
              <h6>{{Product.product_details}}&nbsp;({{Product.plan}})</h6>
            </div>
            <div class="col-md-2">{{ Product.price | currency:'INR' }}</div>
  
            <div class="col-md-2" *ngIf="!loadingRemoveProduct || loadingRemoveProduct !== Product._id; else loading">
              <span class="glyphicon glyphicon-trash cursor" aria-hidden="true" style="color:red" (click)="removeFromCart(Product._id)"></span>
            </div>
            
            <ng-template #loading>
              <div class="col-md-2">
                <div class="spinner"></div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="right">
        <h4>Price Details:</h4>
        <hr />
        <div class="col-md-6">Price ({{ count }} Items)</div>
        <div class="col-md-6">{{cartProducts?.totalAmount | currency:'INR'}}</div>
        <div class="col-md-6">Discount</div>
        <div class="col-md-6">{{'0'}} %</div>
        <div class="col-md-6"><h4>Total Amount</h4></div>
        <div class="col-md-6"><h6 class="margintop">{{cartProducts?.totalAmount | currency:'INR'}}</h6></div>
        <div class="col-md-12 text-center">
          <button class="btn-sm btn btn-info" routerLink="/checkout"> Checkout</button>
        </div>
      </div>
    </div>
    <div class="noCartItems" *ngIf="!cartProducts?.products.length">
      <img src="assets/img/EmptyCart.png" width="20%"><br>
      <h2>Your Cart is <span class='text-danger'>Empty!</span>
        Add items to it now.</h2>
    </div>
  </div>
</div>
<div class="container2" *ngIf="pageLoader">
<div class="spinner2">

</div>
</div>
