<div class="container">
  <div class="left row">
    <div class="col-12">
      <h4 class="margin-top">Checkout</h4>
      <div class="card">
        <h6>Payment Details</h6>
        <div class="card-body col-md-8">
          <form [formGroup]="PaymentDetials" (ngSubmit)="onPayment()">
            <div class="formgroup">
              <input
                type="radio"
                class="form-check-input"
                id="upi"
                value="upi"
                formControlName="paymentMethod"
              />
              <label for="upi" class="cursor"> &nbsp;&nbsp;<img class='' src="assets/img/upilogo.png" height="20px" width="30px"> UPI </label>
              <br />
              <div
                class="upiPayment"
                *ngIf="PaymentDetials.get(['paymentMethod']).value == 'upi'"
              >
                <form
                  [formGroup]="UPIPaymentDetials"
                  (ngSubmit)="onUPIPaymentMode()"
                >
                  <div class="formgroup">
                    <label for="upiId">Your UPI Id</label>
                    <div class="col-12 margin-top">
                      <input
                        type="text"
                        class="col-md-6 borderOuter"
                        placeholder="Enter your UPI Id"
                        formControlName="upiId"
                      />

                      <button
                        class="btn-sm btn btn-success"
                        style="margin-left: 30px"
                        (click)="verifyUpiId(this.UPIPaymentDetials.value.upiId)"
                        *ngIf="!loader"
                        [disabled]="UPIPaymentDetials.invalid"
                      >
                        Verify
                      </button>
                      <button
                        class="btn-sm btn btn-success"
                        style="margin-left: 30px"
                        *ngIf="loader"
                      >
                        <div class="spinner"></div>
                      </button>
                    </div>
                    <p class="margin-top">Pay by any UPI app</p>
                  </div>
                </form>
              </div>
              <input
                type="radio"
                class="form-check-input"
                id="card"
                value="card"
                formControlName="paymentMethod"
              />
              <label for="card" class="cursor">
                &nbsp;&nbsp;<img class='' src="assets/img/cardlogo.png" height="10px" width="30px"> Credit/ Debit/ ATM Card
              </label>

              <div
                class="upiPayment"
                *ngIf="PaymentDetials.get(['paymentMethod']).value == 'card'"
              >
                <form
                  [formGroup]="CardPaymentDetials"
                  (ngSubmit)="onCardPaymentMode()"
                >
                <div class="formgroup col-md-12">
                  <label for="upiId margin-top">Name on Card</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter your name on card"
                      formControlName="name"
                    />
                  </div>

                  <div class="formgroup col-md-6">
                    <label for="upiId margin-top">Your card number</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter your card number"
                        formControlName="cardNumber"
                        maxlength="16"
                      />
                    </div>
                    <div class="form-group col-md-2">
                        <label for="cvv">
                          CVV
                        </label>
                        <input type="text" class="form-control" formControlName="cvv" placeholder="..." maxlength="3">
                    </div>
                    <div class="form-group col-md-3">
                      <label for="validity">
                       Valid Through
                      </label>
                      <input type="date" class="form-control" formControlName="validity" placeholder="MM/YYYY">
                  </div>

                </form>
              </div>
            </div>
          </form>
        </div>
        <div class="col-md-3 rightsec">
          <h4>Summary</h4>
          <p class="col-md-6">Original Price:</p>
          <p class="col-md-6">{{cartProducts?.totalAmount | currency:'INR'}}</p>
          <p class="col-md-6">Discount:</p>
          <p class="col-md-6">{{'0 %'}}</p><br>
          <hr class="col-md-10">
          <p class="col-md-6">Total Price:</p>
          <p class="col-md-6">{{cartProducts?.totalAmount | currency:'INR'}}</p>
            
          <div class="col-md-12" style="color:grey; margin-bottom:10px">
            By compliting your purchase you agree to these Terms of Service
          </div>

          <div class="text-center">
            <button class="btn-sm btn btn-success" type="submit" [disabled]="" (click)="onPaymentPage()" data-toggle="modal" data-target="#myModal2" [disabled]="UPIPaymentDetials.invalid && CardPaymentDetials.invalid">Proceed</button>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</div>



<!-- Payment Modal  -->
<div id="myModal2" class="modal fade margin-top2" role="dialog" data-backdrop="static">
  <div class="modal-dialog margin-top5">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-body">
        <div class="container2" *ngIf="loader2" >
          <div>Do not press back button or refresh page</div>
          <div class="payment-loader">
            <div class="pad">
             <div class="chip"></div>
            <div class="line line1"></div>
            <div class="line line2"></div>
          </div>
          <div class="loader-text">
            Please wait while payment is loading
          </div>
          
        </div>
        </div>
        <div class="container3" *ngIf="!loader2">
          <h3>Bank Server Busy !</h3>
          <h6>Transaction Failed...</h6>
          <button type="button" #myModal2Close class="close" data-dismiss="modal">&times;</button>
       </div>
      </div>
    </div>
  </div>
</div>

