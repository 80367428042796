
			
			
			
            <section id="testimonials" class="page-section">
                <div class="container">
                    <div class="section-title" data-animation="fadeInUp">
                        <h2 class="title">PROJECTS</h2>
                    </div>
                    <div class="row">
                     	<div class="col-md-12 text-center">
							<div class="boxed-block inline-block margin-10" data-animation="fadeInLeft">
                                <a href="#"><img src="img/sections/clients/1.png" width="130" height="130" alt=""></a>
							</div>
                            <div class="boxed-block inline-block margin-10" data-animation="fadeInRight">
                                <a href="#"><img src="img/sections/clients/2.png" width="130" height="130" alt=""></a>
							</div>
							<div class="boxed-block inline-block margin-10" data-animation="fadeInLeft">
                                <a href="#"><img src="img/sections/clients/3.png" width="130" height="130" alt=""></a>
							</div>
							<div class="boxed-block inline-block margin-10" data-animation="fadeInRight">
                                <a href="#"><img src="img/sections/clients/4.png" width="130" height="130" alt=""></a>
							</div>
							<div class="boxed-block inline-block margin-10" data-animation="fadeInLeft">
                                <a href="#"><img src="img/sections/clients/5.png" width="130" height="130" alt=""></a>
							</div>
							<div class="boxed-block inline-block margin-10" data-animation="fadeInRight">
                                <a href="#"><img src="img/sections/clients/6.png" width="130" height="130" alt=""></a>
							</div>
							<div class="boxed-block inline-block margin-10" data-animation="fadeInLeft">
                                <a href="#"><img src="img/sections/clients/3.png" width="130" height="130" alt=""></a>
							</div>
							<div class="boxed-block inline-block margin-10" data-animation="fadeInRight">
                                <a href="#"><img src="img/sections/clients/2.png" width="130" height="130" alt=""></a>
							</div>
							<div class="boxed-block inline-block margin-10" data-animation="fadeInLeft">
                                <a href="#"><img src="img/sections/clients/1.png" width="130" height="130" alt=""></a>
                            </div>
                            <div class="boxed-block inline-block margin-10" data-animation="fadeInRight">
                                <a href="#"><img src="img/sections/clients/6.png" width="130" height="130" alt=""></a>
							</div>
							<div class="boxed-block inline-block margin-10" data-animation="fadeInLeft">
                                <a href="#"><img src="img/sections/clients/3.png" width="130" height="130" alt=""></a>
							</div>
							<div class="boxed-block inline-block margin-10" data-animation="fadeInRight">
                                <a href="#"><img src="img/sections/clients/2.png" width="130" height="130" alt=""></a>
							</div>
							<div class="boxed-block inline-block margin-10" data-animation="fadeInLeft">
                                <a href="#"><img src="img/sections/clients/1.png" width="130" height="130" alt=""></a>
							</div>
							
								<!-- clients -->
                        </div>                    
					</div>
                </div>
            </section>
            <br>
            <br>
            <br>