<div id="page">
  <!-- Page Loader -->
  <div id="pageloader">
    <div class="loader-item fa fa-spin text-color"></div>
  </div>

  <app-header></app-header>

  <router-outlet></router-outlet>

  <app-footer></app-footer>
</div>
