<!-- Top Bar -->
<div id="top-bar" class="top-bar-section top-bar-bg-color">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xs-12 col-sm-12 mob-space">
        <!-- Top Contact -->
        <div class="top-contact link-hover-black">
          <div class="">
            <span class="galaxy" style="font-size: 11px; margin-right: 35px"
              ><i class="fa fa-envelope"></i>
              support&#64;virtualgaintechnologies.com</span
            ><span style="font-size: 11px"
              ><i class="fa fa-phone"></i> + 91 9922011055</span
            >
          </div>
        </div>
        <!-- Top Social Icon -->
        <div class="top-social-icon icons-hover-black">
          <a target="_blank" href="https://www.facebook.com/virtualgaintech"
            ><i class="fa fa-facebook"></i
          ></a>
          <a target="_blank" href="https://twitter.com/GainVirtual"
            ><i class="fa fa-twitter"></i
          ></a>
          <a
            target="_blank"
            href="https://www.youtube.com/channel/UC5Acps3e59EjNSt_fJF28hw"
            ><i class="fa fa-youtube"></i
          ></a>
          <a
            target="_blank"
            href="https://www.linkedin.com/company/virtual-gain-technology/"
            ><i class="fa fa-linkedin"></i
          ></a>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Top Bar -->

<header id="sticker" class="sticky-navigation">
  <!-- Sticky Menu -->
  <div class="sticky-menu relative">
    <!-- navbar -->
    <div class="navbar navbar-default navbar-bg-light" role="navigation">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-9">
            <div class="navbar-header">
              <!-- Button For Responsive toggle -->
              <button
                type="button"
                class="navbar-toggle"
                data-toggle="collapse"
                data-target=".navbar-collapse"
              >
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
              </button>
              <!-- Logo -->

              <a class="navbar-brand" href="home">
                <img
                  class="site_logo"
                  style="margin: 16px 0px 13px 0px"
                  alt="Site Logo"
                  src="assets/img/vgtlogo.png"
                />
              </a>
              <a
                class="navbar-brand dev site_logo ip ipp"
                href="home"
                style="
                  margin: 26px 0px 13px 15px;
                  color: #4b78af;
                  font-size: 23px;
                  font-weight: 800;
                "
                >VIRTUAL GAIN
              </a>
            </div>

            <div class="navbar-collapse collapse">
              <ul
                class="nav navbar-nav mac-s"
                style="margin-right: 350px; font-size: 16px"
              >
                <!-- Home  Mega Menu -->

                <li>
                  <a class="abc" href="home">HOME</a>
                </li>

                <li>
                  <a class="abc" href="about-us">ABOUT</a>
                </li>
                <li>
                  <a
                    class="devmaxhide"
                    href="/#"
                    title="Dismissible popover"
                    data-placement="bottom"
                    data-toggle="popover"
                    data-html="true"
                    data-container="body"
                    data-content='
                              <div class="row white">
                                  <div class="item-box icons-color hover-white col-sm-6 col-md-4">
                                    
                                      <i class="icon-shrink fa-2x"></i> 
                                      <a href="/custom-web-developement"><h6  class="title" style="margin: 0 0 13px;">CUSTOMIZED WEB DEVELOPMENT</h6></a>
  
                                      <i class="icon-shrink fa-2x"></i> 
                                    
                                      <a href="/mobileapp"> <h6 class="title" style="margin: 0 0 13px;">MOBILE APP DEVELOPMENT
                                   </h6></a>
  
                                   <i class="icon-shrink fa-2x"></i> 
                                     
                                    <a href="/InternetMarketing">  <h6 class="title" style="margin: 0 0 13px;">DIGITAL MARKETING
                                      </h6></a>
                                  
                                 </div>
                                  <div class="item-box icons-color hover-white col-sm-6 col-md-4">
                                    <i class="icon-shrink fa-2x"></i> 
                                    <a href="/video-creation"><h6 class="title" style="margin: 0 0 13px;">VIDEO CREATION</h6></a>
  
                                    <i class="icon-shrink fa-2x"></i> 
                                    <a href="/label-design"><h6  class="title" style="margin: 0 0 13px;">PACKAGE AND LABEL DESIGN</h6></a>
  
                                    <i class="icon-shrink fa-2x"></i> 
                                    <a href="/logo-design"><h6  class="title" style="margin: 0 0 13px;">LOGO DESIGN</h6></a>  
                                  </div>
                                  <div class="item-box icons-color hover-white col-sm-6 col-md-4">
                                    <i class="icon-shrink fa-2x"></i> 
                                     
                                    <a href= "/server-cloud-service"> <h6 class="title" style="margin: 0 0 13px;">SERVER & CLOUD
                                     </h6> </a>
  
                                      <i class="icon-shrink fa-2x"></i> 
                                     
                                      <a href= "/email-offiec365-service"> <h6 class="title" style="margin: 0 0 13px;">EMAIL & OFFICE365
                                       </h6> </a>
                                       <i class="icon-shrink fa-2x"></i> 
                                     
                                     <a href= "/aws-azure-service"> <h6 class="title" style="margin: 0 0 13px;">AWS & AZURE
                                      </h6> </a>
                                  </div>
                                
  
                              </div> '
                    ><span style="font-size: 14px; color: white"
                      >PRODUCTS & SERVICES</span
                    ></a
                  >
                </li>

                <li class="devhide" style="margin-top: 6px; margin-bottom: 7px">
                  <a href="#">PRODUCTS & SERVICES</a>
                  <ul
                    class="dropdown-menu"
                    style="
                      margin-top: 10px !important;
                      margin-left: 32px !important;
                    "
                  >
                    <li>
                      <a href="custom-web-developement"
                        >CUSTOMIZED WEB DEVELOPMENT</a
                      >
                    </li>
                    <li>
                      <a href="mobileapp"> MOBILE APP DEVELOPMENT</a>
                    </li>
                    <!-- <li>
                      <a href="Blockchain">BLOCKCHAIN DEVELOPMENT</a>
                    </li> -->
                    <li>
                      <a href="InternetMarketing">DIGITAL MARKETING</a>
                    </li>

                    <li>
                      <a href="video-creation">VIDEO CREATION </a>
                    </li>
                    <li>
                      <a href="label-design">PACKAGE AND LABEL DESIGN </a>
                    </li>
                    <li>
                      <a href="logo-design">LOGO DESIGN </a>
                    </li>
                    <li>
                      <a href="server-cloud-service">SERVER & CLOUD </a>
                    </li>
                    <li>
                      <a href="email-offiec365-service">EMAIL & OFFICE365 </a>
                    </li>
                    <li>
                      <a href="aws-azure-service">AWS & AZURE</a>
                    </li>
                  </ul>
                </li>

                <!-- <li>
                <a class="abc" href="project">PROJECT</a>
             </li> -->
                <li>
                  <a class="abc" href="career">CAREER</a>
                </li>
                <li class="devhide">
                  <a href="feature">FEATURES</a>
                </li>
                <li class="devhide">
                  <a href="technology">TECHNOLOGY</a>
                </li>

                <li>
                  <a class="abc" href="contact-us">CONTACT</a>
                </li>
              </ul>
            </div>
          </div>
          <!-- /.col-md-12 -->
          <div class="col-md-2">
            <li class="m-t mobile">
              <a href="#" class="font-vgn" style="padding-left: 0px"
                >VIRTUAL GAIN</a
              >
            </li>
          </div>
          <div class="col-md-1 flexbox cursor" >
            <div class="margin-top" routerLink="/cart" *ngIf="token">
              <div class="glyphicon glyphicon-shopping-cart position-relative cursor" aria-hidden="true">
                <div class="circle" *ngIf="count>0"><span style="line-height: 20px;">{{count}}</span></div>
              </div>
            </div>
            <div class="margin-top">
              <button
                class="btn-sm cursor"
                style="background-color: #4b78af"
                data-toggle="modal"
                data-target="#myModal"
                *ngIf="!token else ElseBlock"
                id="onLogin"
              >
                Login
              </button>
              <ng-template #ElseBlock>
                <div class="p-2">
                  <div class="dropdown">
                  <span class="glyphicon glyphicon-user dropdown-toggle cursor" aria-hidden="true" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"></span>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenu1" style="left:auto; right:0">
                      <li><a href="#">{{emailId}}</a></li>
                      <li><a (click)="onLogout()">logout</a></li>
                    </ul>
                  </div>
                </div>
              </ng-template>
              
            </div>
            
          </div>
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container -->
    </div>
    <!-- navbar -->
  </div>
  <!-- Sticky Menu -->
</header>

<!-- Login Modal  -->
<div id="myModal" class="modal fade" role="dialog" data-backdrop="static">
  <div class="modal-dialog margin-top5">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" #myModalClose class="close" data-dismiss="modal">
          &times;
        </button>
        <br />
        <div class="form">
          <form [formGroup]="loginForm" (ngSubmit)="onLogin()">
            <div class="form-group">
              <label for="email" class="form-label text-dark"> Email </label>
              <input type="email" class="form-control" placeholder="Enter your email" formControlName="email">
            </div>
            <div class="form-group">
              <label for="password" class="form-label text-dark"> Password </label>
              <input type="password" class="form-control" placeholder="Enter your password" formControlName="password">
            </div>
            
            <div class="form-group text-center">
              <button type="submit" *ngIf="!loader" (click)="onLogin()" class="btn-sm" data-dismiss="modal" [disabled]="loginForm.invalid" style="background-color: #4b78af">
                Continue
              </button>
              <button *ngIf="loader" type="button" class="btn-sm" style="background-color: #4b78af">
                <div class="spinner"></div>
              </button>

            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
