<section class="page-section">
  <div class="container">
    <!-- <div class="section-title white">
                <h2 class="title">PACKAGE AND LABEL DESIGN
  
              </h2>
          </div> -->
    <div class="row">
      <div class="col-sm-6 col-md-4">
        <div
          id="carousel-example-generic"
          class="carousel slide"
          data-ride="carousel"
        >
          <!-- Indicators -->
          <ol class="carousel-indicators">
            <li
              data-target="#carousel-example-generic"
              data-slide-to="0"
              class="active"
            ></li>
            <li data-target="#carousel-example-generic" data-slide-to="1"></li>
            <li data-target="#carousel-example-generic" data-slide-to="2"></li>
          </ol>
          <!-- Wrapper for slides -->
          <div class="carousel-inner" role="listbox">
            <div class="item active">
              <img
                src="assets/img/label1.png"
                width=""
                height=""
                alt=""
                title=""
              />
            </div>

            <div class="item">
              <img src="assets/img/label6.png" alt="" title="" />
            </div>
          </div>
          <!-- Controls -->
          <a
            class="left carousel-control"
            href="#carousel-example-generic"
            role="button"
            data-slide="prev"
          >
            <span class="fa fa-angle-left fa-2x" aria-hidden="true"></span>
            <span class="sr-only">Previous</span></a
          >
          <a
            class="right carousel-control"
            href="#carousel-example-generic"
            role="button"
            data-slide="next"
          >
            <span class="fa fa-angle-right fa-2x" aria-hidden="true"></span>
            <span class="sr-only">Next</span></a
          >
        </div>
      </div>
      <div class="col-md-8 col-sm-12" data-animation="fadeInRight">
        <h2 class="title">Gorgeous Custom Label design for your product</h2>
        <p>
          A great label design is like a great book cover informative,
          intriguing and inviting.Plus it makes customers say "yes" to your
          product.Get a custom product label design from us and our designers
          will create something you'll love.
        </p>
        <p>
          Many underestimate the importance of excellent and appealing
          package/label design in todays highly competitive market. Your package
          design should be creative and innovative so that it can stand out
          among the crowd.Our experts help you in selling off your products from
          online platforms, e-commerce websites or local stores by building
          amazing package and Labels designs!
        </p>
      </div>
    </div>
  </div>
</section>

<section id="clients" class="page-section">
  <div class="container">
    <div class="row">
      <div class="col-md-12 text-center">
        <div
          class="owl-carousel navigation-1"
          data-pagination="false"
          data-items="5"
          data-autoplay="true"
          data-navigation="true"
        >
          <a href="#">
            <img src="assets/img/label2.png" width="" height="" alt="" />
          </a>
          <a href="#">
            <img src="assets/img/label3.png" width="" height="" alt="" />
          </a>
          <a href="#">
            <img src="assets/img/label4.png" width="" height="" alt="" />
          </a>
          <a href="#">
            <img src="assets/img/label5.png" width="" height="" alt="" />
          </a>
          <a href="#">
            <img src="assets/img/label2.png" width="" height="" alt="" />
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="container" style="margin-top: 100px; margin-bottom: 50px">
  <div class="text-center margin-bottom1">
    <h4>Choose The Best Plan That’s For You</h4>
  </div>
  <div class="row">
    <div class="col-md-3 margin-top1">
      <div class="card text-center">
        <div class="title">
          <i class="fa fa-rocket" aria-hidden="true"></i>
          <!-- <h2>Basic</h2> -->
        </div>
        <div class="price">
          <h4>
            ₹ 500
            <!-- <span style="font-size: 20px">/month</span> -->
          </h4>
        </div>
        <div class="option">
          <ul>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Team of Project Head
              & 1 Dedicated Designer
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>2 Telephonic
              Consultation with Project Head
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>3 Design Samples
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>5 Revision Rounds
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Copyright Ownership
              Transfer of Selected Design
            </li>
          </ul>
        </div>
        <a (click)="AddtoCart(LabelData?.plan_details[0])">Add to cart</a>
      </div>
    </div>
    <div class="col-md-3 margin-top1">
      <div class="card text-center">
        <div class="title">
          <i class="fa fa-rocket" aria-hidden="true"></i>
          <!-- <h2>Basic</h2> -->
        </div>
        <div class="price">
          <h4>
            ₹ 700
            <!-- <span style="font-size: 20px">/month</span> -->
          </h4>
        </div>
        <div class="option">
          <ul>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Team of Project Head
              & 1 Dedicated Designer
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>2 Telephonic
              Consultation with Project Head
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>5 Design Samples
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>10 Revision Rounds
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Copyright Ownership
              Transfer of Selected Design
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>1 Intro Video for
              Selected Design
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>CMYK Colour Design
            </li>
          </ul>
        </div>
        <a (click)="AddtoCart(LabelData?.plan_details[1])">Add to cart</a>
      </div>
    </div>
    <div class="col-md-3 margin-top1">
      <div class="card text-center">
        <div class="title">
          <i class="fa fa-rocket" aria-hidden="true"></i>
          <!-- <h2>Basic</h2> -->
        </div>
        <div class="price">
          <h4>
            ₹ 900
            <!-- <span style="font-size: 20px">/month</span> -->
          </h4>
        </div>
        <div class="option">
          <ul>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Team of Project Head
              & 1 Dedicated Designer
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>5 Telephonic
              Consultation with Project Head
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>5 Design Samples
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Unlimited Revision
              Rounds
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Copyright Ownership
              Transfer of Selected Design
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>1 Intro Video for
              Selected Design
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>CMYK Colour Design
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Presentation on
              Realistic Product Mockup
            </li>
          </ul>
        </div>
        <a (click)="AddtoCart(LabelData?.plan_details[2])">Add to cart</a>
      </div>
    </div>
    <div class="col-md-3 margin-top1">
      <div class="card text-center">
        <div class="title">
          <i class="fa fa-rocket" aria-hidden="true"></i>
          <!-- <h2>Basic</h2> -->
        </div>
        <div class="price">
          <h4>
            ₹ 1,200
            <!-- <span style="font-size: 20px">/month</span> -->
          </h4>
        </div>
        <div class="option">
          <ul>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Team of Project Head
              & 1 Dedicated Designer
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>2 Telephonic
              Consultation with Project Head
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>5 Design Samples
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Unlimited Revision
              Rounds
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Copyright Ownership
              Transfer of Selected Design
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>1 Intro Video for
              Selected Design
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>CMYK Colour Design
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Presentation on
              Realistic Product Mockup
            </li>
            <li>
              <i class="fa fa-check" aria-hidden="true"></i>Handover of Print
              Ready Source Files
            </li>
          </ul>
        </div>
        <a (click)="AddtoCart(LabelData?.plan_details[3])">Add to cart</a>
      </div>
    </div>
  </div>
</div>
