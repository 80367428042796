import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductsService } from '../services/products.service';
import { NotificationService } from '../services/notification.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent {
  @ViewChild('myModal2Close') modalClose;
  PaymentDetials: FormGroup;
  UPIPaymentDetials: FormGroup;
  CardPaymentDetials: FormGroup;
  cartProducts: any = [];
  token: any;
  error: string | null = null;
  isValid = false;
  loader: boolean = false;
  loader2: boolean = true;

  constructor(private fb: FormBuilder, private product: ProductsService, private notify:NotificationService, private router:Router) {
    this.token = localStorage.getItem('token');
  }

  ngOnInit() {
    this.createPaymentdetailsForm();
    this.createUPIPaymentForm();
    this.createCardPaymentForm();
    this.getCartItems();
  }
  
  createPaymentdetailsForm() {
    this.PaymentDetials = this.fb.group({
      paymentMethod: ['', Validators.required],      
    })
  }

  createUPIPaymentForm() {
    this.UPIPaymentDetials = this.fb.group({
      upiId: ['', [Validators.required]],      
    })
  }

  createCardPaymentForm() {
    this.CardPaymentDetials = this.fb.group({
      name:['',Validators.required],
      cardNumber: ['', [Validators.required,Validators.maxLength(16)]], 
      cvv: ['', [Validators.required,Validators.maxLength(3)]],
      validity:['',Validators.required]
    })
  }


  onPayment() {

  }

  onUPIPaymentMode() {

  }

  onCardPaymentMode() {
    
  }

  onPaymentPage() {
    setTimeout(() =>
    {
      this.loader2 = !this.loader2;
      setTimeout(() =>
        {
        this.loader2 = !this.loader2;
        this.modalClose.nativeElement.click();
        this.router.navigate(['/cart']);
        }, 5000)
    }, 10000)

    

  }

  private isValidUPI(upiId: string): boolean {
    const regex = /^[a-zA-Z0-9._]+@[a-zA-Z0-9.-]+$/;
    return regex.test(upiId);
  }

  verifyUpiId(upiId: any) {
    this.loader = true;
    setTimeout(() => {
      if (!this.isValidUPI(upiId)) {
        this.loader = false;
        this.notify.showError('Invalid UPI ID', '')
      } else {
        this.loader = false;
        this.notify.showSuccess('Valid UPI ID', '')
      }
    },2000)
  }

  getCartItems() {
    this.product.getCartProducts(this.token).subscribe({
      next: (payload: any) => {
        const response = JSON.parse(JSON.stringify(payload));
        if (response.error) {
          this.notify.showError(response.message, '');
        } else {
          this.cartProducts = response.data;
        }
      }, error: (err: any) => {
        this.notify.showError(err.error.message || "Internal Server Error", "");
      }
    })
  }

  onEnable() {
    return this.UPIPaymentDetials.invalid || this.CardPaymentDetials.invalid
  }
}
